var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-gray-100 h-full p-20" }, [
    _c("div", { staticClass: "max-w-3xl bg-white shadow sm:rounded-lg " }, [
      _c("div", { staticClass: "px-4 py-5 sm:p-6" }, [
        _c(
          "h3",
          { staticClass: "text-lg leading-6 font-medium text-gray-900" },
          [_vm._v(" Login with your shopify domain ")]
        ),
        _c("p", { staticClass: "text-gray-600 text-sm my-4" }, [
          _vm._v(
            " If you are using in mobile please goto your shopify admin page -> Apps -> Click our app "
          )
        ]),
        _c("div", {
          staticClass: "mt-2 max-w-xl text-sm leading-5 text-gray-500"
        }),
        _c("div", { staticClass: "mt-5 sm:flex sm:items-center" }, [
          _c("div", { staticClass: "max-w-xs w-full" }, [
            _c("label", { staticClass: "sr-only", attrs: { for: "email" } }, [
              _vm._v("Login with your shopify domain ")
            ]),
            _c("div", { staticClass: "relative rounded-md shadow-sm" }, [
              _c("div", { staticClass: "max-w-lg flex rounded-md shadow-sm" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.subdomain,
                      expression: "subdomain"
                    }
                  ],
                  staticClass:
                    "flex-1 form-input block w-full min-w-0 rounded-none rounded-l-md transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                  attrs: { id: "username" },
                  domProps: { value: _vm.subdomain },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.subdomain = $event.target.value
                    }
                  }
                }),
                _c(
                  "span",
                  {
                    staticClass:
                      "inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                  },
                  [_vm._v(" .myshopify.com ")]
                )
              ])
            ])
          ]),
          _c(
            "span",
            {
              staticClass:
                "mt-3 inline-flex rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto"
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "w-full inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 sm:w-auto sm:text-sm sm:leading-5",
                  attrs: { type: "button" },
                  on: { click: _vm.login }
                },
                [_vm._v(" Login ")]
              )
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }