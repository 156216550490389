<template>
  <div class="bg-gray-100 h-full p-20">
    <div class="max-w-3xl bg-white shadow sm:rounded-lg ">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Login with your shopify domain
        </h3>
        <p class="text-gray-600 text-sm my-4">
          If you are using in mobile please goto your shopify admin page -> Apps
          -> Click our app
        </p>
        <div class="mt-2 max-w-xl text-sm leading-5 text-gray-500"></div>
        <div class="mt-5 sm:flex sm:items-center">
          <div class="max-w-xs w-full">
            <label for="email" class="sr-only"
              >Login with your shopify domain
            </label>
            <div class="relative rounded-md shadow-sm">
              <div class="max-w-lg flex rounded-md shadow-sm">
                <input
                  id="username"
                  v-model="subdomain"
                  class="flex-1 form-input block w-full min-w-0 rounded-none rounded-l-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
                <span
                  class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                >
                  .myshopify.com
                </span>
              </div>
            </div>
          </div>
          <span
            class="mt-3 inline-flex rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto"
          >
            <button
              type="button"
              class="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 sm:w-auto sm:text-sm sm:leading-5"
              @click="login"
            >
              Login
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      subdomain: ""
    };
  },
  methods: {
    async login() {
      const domain = `${this.subdomain}.myshopify.com`;
      const response = await this.$store.dispatch("login", { domain });
      if (response.ok) {
        window.location.href = response.authUrl;
      } else if (response.ok === false) {
        alert(`Store not connected with our app. 
             please goto your shopify admin page -> Apps
             -> Click our app`);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
